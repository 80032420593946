module.exports = {
  adminDomain: '',
  allowStubs: false,
  apiPath: '/api',
  assetsPath: '/assets',
  cfDomain: '',
  cmsDirectory: '/bin/v1',
  contentRoot: '/content/dam/waitrosegroceriescms',
  failsafe: true,
  features: {
    apmRum: false,
    apmRumErrorLogging: false,
    browse_mealDealEndDate1Day: false,
    browse_mealDealEndDate2Day: false,
    browse_mealDealEndDateToday: false,
    browse_offersLinksWithRelNofollow: false,
    checkout_wismo: false,
    cspCheckoutEnforceRules: false,
    cspCheckoutScriptUnsafeEval: false,
    eagerSuggest: false,
    enablePredictiveAddress: false,
    EoS_aemCarousel: false,
    EoS_ChristmasHub_ForceValidDate: false,
    EoS_newOffersCall: false,
    glpNLPCarousel: false,
    loyalty_competitions: true,
    loyalty_enableNonIPickSelection: false,
    manualAddressFormOnly: true,
    modalErrorLogging: false,
    multisearch: true,
    nlpCarousel: false,
    onlineClipToCard: false,
    orderItemSubs: false,
    podHighlight: false,
    recommendationsWithFallback: false,
    renderStaticNavLinks: false,
    savePaymentCard: true,
    searchHistory: true,
    sentry: false,
    sentryContentsquare: false, // Contentsquare integration only works if sentry is true
    showPDPRecommendations: false,
    showPlasticCardDelayAlert: false,
    showPrideLogo: false,
    siteBanner: false,
    slots_allDeliverySlotsDisabled: false,
    slots_allSlotsDisabled: false,
    slots_newSlotConfirmationMessage: false,
    slots_useCustomCampaign: false,
    ssrForAll: false,
    throttleLogins: false,
    trolley_christmasHeaderForceValidDate: false,
  },
  featureFlagsToPassToAPI: ['enAppleWallet'],
  googleAnalytics: {
    collectApiUrl: 'https://www.google-analytics.com/debug/mp/collect',
    measurementId: 'G-8YN3DG5SKT',
    // deepcode ignore HardcodedNonCryptoSecret - The GA API key is publicly available
    apiKey: '32cQoUIDR8qFW7e7manC1g',
  },
  graphPath: '/graph',
  impactTrackerId: '22892',
  johnLewisServerPath: 'https://api.integration.project4.com/',
  loyaltyExperiencesServerPath: 'https://integration-api.loyalty-experiences.flex.jl-digital.net',
  ofDomain: '',
  monetateApiChannel: 'a-18da6672/d/dev.waitrose.com',
  cardinalScriptUrl: 'https://songbirdstag.cardinalcommerce.com/edge/v1/songbird.js',
  kountClientID: '888099',
  paymentCardAuthReturn: '/v1/orders/payments/authentication',
  prodHost: 'www.waitrose.com',
  verifoneDomain: 'https://sandbox.omni.verifone.cloud/v1',
  verifoneOrganizationID: '5d15d6671a8e7b2d3a83cc5a',
  reCaptchaPublicKey: '6LfbWzEaAAAAALZVveWFuQj_9sLLIzGYfuw4VPK8',
  root: '/ecom',
  thirdPartyPath: '/external',
  timeouts: {
    interstitials: {
      response: 2000,
      deadline: 4000,
    },
    minimumOrderValues: {
      response: 5000,
      deadline: 7000,
    },
  },
  verifoneCardFormPostUrl: 'https://vg-cst.cxmlpg.com/vanguard.aspx',
  wcsDomain: '',
  authDomain: '',
  tokenExScriptUrl: 'https://api.tokenex.com/inpage/js/TokenEx-Lite.js',
  tokenExPublicKey:
    'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAvWpIQFjQQCPpaIlJKpegirp5kLkzLB1AxHmnLk73D3TJbAGqr1QmlsWDBtMPMRpdzzUM7ZwX3kzhIuATV4Pe7RKp3nZlVmcrT0YCQXBrTwqZNh775z58GP2kZs+gVfNqBampJPzSB/hB62KkByhECn6grrRjiAVwJyZVEvs/2vrxaEpO+aE16emtX12RgI5JdzdOiNyZEQteU6zRBRJEocPWVxExaOpVVVJ5+UnW0LcalzA+lRGRTrQJ5JguAPiAOzRPTK/lYFFpCAl/F8wtoAVG1c8zO2NcQ0Pko+fmeidRFxJ/did2btV+9Mkze3mBphwFmvnxa35LF+Cs/XJHDwIDAQAB',
  recipesPerPage: 54,
  sitemapRecipesCategories:
    'https://waitrose-prod65.adobecqms.net/content/waitrosegroceriescms/en/recipes.sitemap.recipes.categories.xml',
  sitemapRecipesDetailPages:
    'https://api.ecom.waitrose.com/recipes-prod/v1/sitemap.recipe.detail.pages.xml',
  version: process.env.VERSION,
  apmRum: {
    url: 'https://wtr-ecom-logging-nonprod.apm.eu-west-1.aws.found.io',
    transactionSampleRate: 1.0,
  },
  sitemapContentPages:
    'https://waitrose-prod.adobecqms.net/content/waitrosegroceriescms/en/wtr-content.sitemap.contentpage.xml',
};
